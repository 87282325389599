import { StringOrNumber } from '@/types';
import {
  Edit,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, { createRef } from 'react';
import { ReorderDataTable } from '../ReorderDataTable/ReorderDataTable';

export type Column<TColID extends StringOrNumber = StringOrNumber> = {
  id: TColID;
  label?: string;
  name?: string;
  children?: Array<Column<TColID>>;
};

type CheckedRow<TColID extends StringOrNumber = StringOrNumber> = {
  id: TColID;
  label: string;
  group: string;
  checked: boolean;
};

type Props<TColID extends StringOrNumber = StringOrNumber> = {
  availableColumns: Array<Column<TColID>>;
  value: Array<TColID>;
  headerClassName?: string;
  itemClassName?: string;
  open?: boolean;
  onChangeValue: (value: Array<TColID>) => void;
};

type State = {
  open: boolean;
  rows: Array<CheckedRow>;
}

export class ColumnsEditor extends React.Component<Props, State> {
  contentRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: this.props.open ?? false,
      rows: this.getFlattenedRows(),
    };
  }

  get columns() {
    // somewhat crudely calculate the width of the label column based on the longest label.
    // if this is a bit inaccurate, the worst case is that the column will wrap or be unnecessarily wide.
    let labelWidth = 100;
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx) {
      ctx.font = '14px san-serif';
      this.getFlattenedRows().forEach(row => {
        const textWidth = ctx.measureText(`${row.label} - ${row.group}`).width;
        labelWidth = Math.max(labelWidth, textWidth + 48);
      });
    }

    return [{ id: 'checked', label: '', width: 20 }, { id: 'label', label: 'label', width: Math.min(labelWidth, 600) }];
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.availableColumns !== this.props.availableColumns || prevProps.value !== this.props.value) {
      this.setState({ rows: this.getFlattenedRows() });
    }
  }

  getFlattenedRows() {
    const { value } = this.props;
    const result: Array<CheckedRow> = [];
    const usedNames: Record<string, true> = {};

    // the logic for when we show groups is a bit convoluted, but it's based on the following rules:
    // - if the first column has children, don't show the group name for those children
    // - if there is more than one subsequent column with children, show the group name for all columns (so if there are
    //    multiple vaults we would always show vault names)
    // - if a column is in a group and its label has been previously used, include the group name
    // - Always show group names for the last group if it is not a vault (so we show "Ontology Terms")

    // if the first column has children, flatten the children into the top level
    let availableColumns = this.props.availableColumns ?? [];
    if (availableColumns[0]?.children) {
      availableColumns = [...availableColumns[0].children, ...availableColumns.slice(1)];
    }

    let numVaults = 0;
    for (const column of availableColumns) {
      // for now, naively assume that if the column id is a number, it's a vault
      if (typeof column.id === 'number') {
        ++numVaults;
      }
    }

    const addToResult = (column: Column, group = '', includeGroup = false) => {
      const label = column.label ?? column.name ?? '';
      // show the group if there are multiple vaults OR the name has been used before
      includeGroup = includeGroup || usedNames[label] || numVaults > 1;
      usedNames[label] = true;

      if (column.children) {
        // special case: if the last group isn't a vault, show its group label (e.g., Ontology Terms)
        if (column === availableColumns[availableColumns.length - 1] && typeof column.id === 'string') {
          includeGroup = true;
        }
        column.children.forEach(column => addToResult(column, label, includeGroup));
      } else {
        result.push({
          id: column.id,
          label,
          group: (includeGroup ? group : ''),
          checked: (value.findIndex(v => (column.id == v)) !== -1),
        });
      }
    };
    for (const column of availableColumns) {
      addToResult(column, '');
    }
    // Sort result based on the order of values
    result.sort((a, b) => {
      return value.indexOf(a.id) - value.indexOf(b.id);
    });
    // move unchecked items to the bottom
    result.sort((a, b) => b.checked.toString().localeCompare(a.checked.toString()));
    return result;
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (_event, reason?: ('escapeKeyDown' | 'backdropClick')) => {
    this.setState({ open: false });
    if (reason === 'escapeKeyDown') {
      this.setState({ rows: this.getFlattenedRows() });
    } else {
      const newValue = this.state.rows.filter(row => row.checked).map(row => row.id);
      if (JSON.stringify(newValue) !== JSON.stringify(this.props.value)) {
        this.props.onChangeValue(newValue);
      }
    }
  };

  handleChangeOrder = (rows: Array<CheckedRow>) => {
    this.setState({ rows });
  };

  renderCell = (
    columnId: StringOrNumber,
    data: CheckedRow,
  ) => {
    if (columnId === 'label') {
      return <>{data.label}{data.group ? <span className='group-name'>&nbsp;({data.group})</span> : null}</>;
    }
    if (columnId === 'checked') {
      return <Checkbox checked={data.checked} inputProps={{
        'aria-label': `Toggle ${data.label}`,
        'data-column-id': data.id,
      } as any} onChange={evt => {
        this.setState({
          rows: this.state.rows.map(row => {
            if (row.id === data.id) {
              return { ...row, checked: evt.target.checked };
            }
            return row;
          }),
        });
      }} />;
    }
  };

  render() {
    return <span className='ColumnsEditor'>
      <Tooltip title={'Edit Columns'}>
        <IconButton
          aria-controls="draggable-menu" aria-haspopup="true"
          aria-label='Edit Columns'
          className='search_results__button'
          onClick={this.handleClick}>
          <Edit />
        </IconButton>
      </Tooltip>

      <Dialog open={this.state.open} onClose={this.handleClose} className="ColumnsEditor-dialog">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={this.contentRef} className='dialog-contents'>
          <ReorderDataTable
            showHeader={false}
            columns={this.columns}
            rows={this.state.rows}
            renderCell={this.renderCell}
            onChangeOrder={this.handleChangeOrder} />
        </DialogContent>
      </Dialog>
    </span>;
  }
}
