import { debounce } from 'lodash'

import basicMiddleware from './basicMiddleware.js'
import { GET_LINK_SUGGESTIONS, suggestLinks } from '@/shared/actions/linkSuggestions.js'
import { LINK_SUGGESTION_THROTTLE as WAIT } from '@/shared/constants.js'

function __getLinkSuggestions({ dispatch, text, state }) {
  const { linkSuggestionsPath } = state.constants
  $.ajax({
    url: linkSuggestionsPath,
    dataType: 'json',
    data: { text, 'molecule_details': true },
    global: false,
  }).done(suggestions => {
    dispatch(suggestLinks(text, suggestions))
  }).fail(() => {
    dispatch(suggestLinks(text, []))
  })
}

const _getLinkSuggestions = debounce(__getLinkSuggestions, WAIT)

const getLinkSuggestions = ({ text }) => (dispatch, getState) => {
  if (!text || text.length == 0) return dispatch(suggestLinks(text, []))
  _getLinkSuggestions({ dispatch, text, state: getState() })
}

const operations = {
  [GET_LINK_SUGGESTIONS]: getLinkSuggestions,
}

export default basicMiddleware({ operations })
