export const GET_LINK_SUGGESTIONS = 'eln/entry/links/suggest/get'
export const SUGGEST_LINKS = 'eln/entry/links/suggest'

export function getLinkSuggestions(text) {
  return {
    type: GET_LINK_SUGGESTIONS,
    payload: { text },
  }
}

export function suggestLinks(text, suggestions) {
  return {
    type: SUGGEST_LINKS,
    payload: { text, suggestions },
  }
}
