import { AnyObject } from '@/types';
import {
  extendObservable,
  IObservableArray,
  isObservableArray, isObservableProp, toJS,
} from 'mobx';
import { ElementDef } from './types/base/elementDef';
import { FieldDef } from './types/base/fieldDef';
import {
  ElementType, GroupType,
  HasChildren, HasContents,
} from './types/index';

type ValueType = FieldDef['valueType'];

export class DDFormUtils {
  static convertToDotNotation(str: string): string {
    // Regular expression to match square brackets with non-numeric content
    const regex = /\[(['"]?)([a-zA-Z_][a-zA-Z0-9_]*)\1\]/g;

    // Replace the matched patterns with dot notation
    return str.replace(regex, '.$2');
  }

  /**
   * Get a value from the data object using path, which is a JSON type notation
   * For example, if data is {orders:[{description:{sku:"10000"}}]}, then:
   *   getValue('orders[0].description.sku', data) should return "1000"
   *
   * However, the value can be coerced into different formats using valueType, for example:
   *   getValue('orders[0].description.sku', data, 'number') should return 1000
   *
   * @param path path to data
   * @param data source object
   * @param valueType optional type
   * @returns
   */
  static getValue(
    path: string,
    data: AnyObject,
    valueType: ValueType | string | 'direct' = 'auto',
  ) {
    path = this.convertToDotNotation(path);
    const container = DDFormUtils.getContainerForPath(path, data);
    const parts = path.split('.');
    const lastPart = parts[parts.length - 1];
    let value = null;
    if (container) {
      value = toJS(container[lastPart]);
    }

    if (valueType === 'direct') {
      return value;
    }

    return DDFormUtils.convertValueToType(value, valueType);
  }

  /**
   * Set a value to the data object using path, which is a JSON type notation, creating any missing objects along the way.
   * For example, if data is {}, then:
   *   setValue('orders[0].description.sku', data, 1000) should cause data to be updated to {orders:[{description:{sku:"10000"}}]}
   *
   * @param path path to data
   * @param data source object
   * @param valueType optional type
   * @returns
   */
  static setValue(
    path: string,
    data: AnyObject,
    value: unknown,
    valueType: ValueType | string = 'auto',
  ) {
    path = this.convertToDotNotation(path);
    const container = DDFormUtils.getContainerForPath(path, data, true);
    const parts = path.split('.');
    const lastPart = parts[parts.length - 1];

    if (container) {
      const newValue = DDFormUtils.convertValueToType(value, valueType);
      if (isObservableArray(container[lastPart])) {
        (container[lastPart] as IObservableArray<any>).replace(newValue); // eslint-disable-line @typescript-eslint/no-explicit-any
      } else {
        const wasObservable = isObservableProp(container, lastPart);
        if (!wasObservable) {
          const newProp = { [lastPart]: newValue };
          extendObservable(container, newProp);
        } else {
          container[lastPart] = newValue;
        }
      }
    }
  }

  /**
   * For a given path, find the container in the data. Only 1D arrays of objects are currently supported
   * @param path
   * @param data
   * @param create
   */
  static getContainerForPath(
    path: string,
    data: AnyObject,
    create = false,
  ): AnyObject | null {
    path = this.convertToDotNotation(path);
    const parts = path.split('.');
    let target = data;
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      if (part.includes('[')) {
        // support for arrays of objects, no > 1d arrays for now
        const arrayParts = part.split('[');
        const arrayName = arrayParts[0];
        const arrayIndex = parseInt(arrayParts[1].split(']')[0], 10);
        if (isNaN(arrayIndex) || arrayIndex < 0) {
          console.error(`error resolving path ${path}`);
          return null;
        }
        let array = target[arrayName];
        if (!array) {
          if (!create) {
            return null;
          }
          target[arrayName] = array = [];
        }

        if (!Array.isArray(array) && !isObservableArray(array)) {
          console.error(
            `while resolving path ${path}, encountered array notation for ${arrayName} that is not an array`,
          );
          return null;
        }
        while (arrayIndex >= array.length) {
          array.push({});
        }
        target = array[arrayIndex];
      } else {
        if (!target[part]) {
          if (!create) {
            return null;
          }
          target[part] = {};
        }
        target = target[part] as AnyObject;
      }
    }
    return target;
  }

  /**
   * Convert a value to the specified type
   * @param value
   * @param valueType
   * @returns
   */
  static convertValueToType(value: unknown, valueType: ValueType | string): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    switch (valueType) {
      case 'boolean':
        return DDFormUtils.convertValueToBoolean(value);

      case 'date':
        return DDFormUtils.convertValueToDate(value);

      case 'number':
        return DDFormUtils.convertValueToNumber(value);

      case 'arrayString':
        return DDFormUtils.convertValueToArrayString(value);

      case 'object':
        return typeof value === 'object' ? value : { value };

      case 'auto':
      default:
        if (value === 0) {
          return '0';
        }
        if (!value && value !== false) {
          value = '';
        }
        break;
    }
    return value;
  }

  static convertValueToBoolean(value: unknown) {
    if (typeof value !== 'boolean') {
      value = typeof value === 'number' ? value !== 0 : value === 'true';
    }
    return !!value;
  }

  static convertValueToNumber(value: unknown): number | undefined | null {
    if (value === undefined || value === null || value === '') {
      // we don't want to convert empty values to 0, because we want to be able to distinguish between
      // empty and 0
      return undefined;
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'boolean') {
      return value ? 1 : 0;
    }
    if (value instanceof Date) {
      return value.getTime();
    }
    return parseFloat('' + value);
  }

  static convertValueToArrayString(value: unknown): Array<string> {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        // assume it's an array of strings for now
        return value;
      }
      return Object.keys(value);
    }
    return ['' + value];
  }

  static convertValueToDate(value: unknown): (Date | null) {
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'number') {
      return new Date(value);
    }
    if (value === null) {
      return null;
    }
    return new Date('' + value);
  }

  static iterateThroughLayout(
    element: ElementDef & (Partial<HasChildren> | Partial<HasContents>),
    callback: (element: ElementDef) => void,
  ) {
    callback(element);
    if ('children' in element) {
      element.children.forEach((child) => {
        if (child) {
          DDFormUtils.iterateThroughLayout(child, callback);
        }
      });
    }
    if ('contents' in element) {
      DDFormUtils.iterateThroughLayout(element.contents, callback);
    }
  }

  static mergeDefaultsWithLayout(
    defaults: { [key: string]: AnyObject; },
    layout: GroupType,
  ) {
    let result = layout;
    if (defaults) {
      const applyDefaults = (element: ElementDef) => {
        const controlAttributes = {
          ...(defaults.default?.controlAttributes as object),
          ...(defaults[element.type]?.controlAttributes as object),
          ...((element as any).controlAttributes as object), // eslint-disable-line @typescript-eslint/no-explicit-any
        };

        const base = defaults[element.type] ?? defaults.default;
        const results = { ...base, ...element, controlAttributes };

        if ('children' in element) {
          const hasChildren = results as unknown as HasChildren;
          hasChildren.children = hasChildren.children.slice();
          for (let i = 0; i < hasChildren.children.length; i++) {
            hasChildren.children[i] = applyDefaults(
              hasChildren.children[i],
            ) as ElementType;
          }
        }
        if ('contents' in element) {
          const hasContents = results as unknown as HasContents;
          hasContents.contents = applyDefaults(
            hasContents.contents,
          ) as GroupType;
        }
        return results;
      };
      result = applyDefaults(result) as GroupType;
    }
    return result;
  }
}
