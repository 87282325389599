/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-css */

import React, { ReactNode } from 'react';

/**
 * A component that only mounts & renders children if it's scrolled into view, visible and not perfectly overlapping other elements.
 *
 * Usage:
 * <RenderIfVisible>
 *   <MyExpensiveComponent/>
 *  </RenderIfVisible>
 *
 *  If enableOnlyIfElementPresentById is passed, the DOM must contain that element or all children will be rendered.
 **/

/**
 * Really test that the element (and its parents) is visible. Sadly, is(":visible") doesn't test parents.
 */
export const isElementReallyVisible = (element: HTMLElement) => {
  if (!element || element.tagName === 'BODY') {
    return true;
  }
  const $el = $(element);
  if ($el.css('visibility') === 'hidden' || $el.css('display') === 'none') {
    return false;
  }
  return isElementReallyVisible(element.parentElement);
};

type Props = {
  children?: ReactNode,
  enableOnlyIfElementPresentById?: string,
};
type State = { wasVisible: boolean };

export class RenderIfVisible extends React.Component<Props, State> {
  static disabled = false;
  static intersectionObserver?: IntersectionObserver;
  // eslint-disable-next-line no-use-before-define
  static elementMap = new Map<HTMLSpanElement, {
    component: RenderIfVisible,
    isHidden?: boolean,
    isScrolledInView?: boolean
  }>();

  readonly ref = React.createRef<HTMLSpanElement>();

  private static initializeObservers() {
    if (RenderIfVisible.intersectionObserver) {
      return; // already run static initializers, so no-op
    }
    // Create an IntersectionObserver to observe viewport changes (no need to clean up)
    const elementMap = RenderIfVisible.elementMap;
    RenderIfVisible.intersectionObserver = new IntersectionObserver(entries => {
      // any elements that overlap will be treated as hidden
      const mapRectToCount: {[rect: string]: number} = {};
      entries.forEach(entry => {
        const rect = JSON.stringify(entry.boundingClientRect);
        mapRectToCount[rect] = mapRectToCount[rect] ? (mapRectToCount[rect] + 1) : 1;
      });

      entries.forEach(entry => {
        const element = entry.target as HTMLSpanElement;
        const visState = elementMap.get(element);
        if (visState) {
          const { component } = visState;
          // if the component was not already previously visible
          if (component && !component.state.wasVisible) {
            const rect = entry.boundingClientRect;
            // and its bounding rectangle doesn't exactly match another component's
            if (mapRectToCount[JSON.stringify(entry.boundingClientRect)] === 1) {
              // and it's visible within the view port (with some extra padding so that components which are just
              // offscreen are still considered to be scrolled into view)
              const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
              const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
              const paddingFraction = 0.75;
              if (
                rect.top >= (-windowHeight * paddingFraction) &&
                rect.left >= (-windowWidth * paddingFraction) &&
                rect.bottom <= (windowHeight + windowHeight * paddingFraction) &&
                rect.right <= (windowWidth + windowWidth * paddingFraction)
              ) {
                // mark it as scrolled into view
                visState.isScrolledInView = true;
                // update its visible state if necessary
                if (visState.isHidden) {
                  visState.isHidden = !isElementReallyVisible(element);
                }
                // and mark if for rendering if visible and in the viewport
                if (visState.isScrolledInView && !visState.isHidden) {
                  component.onBecomeVisible();
                }
              }
            }
          }
        }
      });
    });

    // now set an interval to observe visibility changes (no need to clean up)
    setInterval(() => {
      for (const element of elementMap.keys()) {
        const visState = elementMap.get(element);
        if (visState.isHidden && isElementReallyVisible(element)) {
          visState.isHidden = false;
          if (visState.isScrolledInView) {
            visState.component.onBecomeVisible();
          }
        }
      }
    }, 50);
  }

  constructor(props) {
    super(props);
    this.state = { wasVisible: false };
    RenderIfVisible.initializeObservers();
  }

  get alwaysRenderEvenIfOffscreen() {
    const { enableOnlyIfElementPresentById } = this.props;

    // if enableOnlyIfElementPresentById is passed and the referred to element is not in the DOM, then always render
    // offscreen items. We currently use this to ensure that dose response plots and thumbnails are always rendered except
    // when in the search result table.
    const forceRenderByElementId = enableOnlyIfElementPresentById && !document.getElementById(enableOnlyIfElementPresentById);
    return RenderIfVisible.disabled || forceRenderByElementId;
  }

  onBecomeVisible() {
    if (!this.alwaysRenderEvenIfOffscreen) {
      if (!this.state.wasVisible) {
        RenderIfVisible.elementMap.delete(this.ref.current);
        RenderIfVisible.intersectionObserver.unobserve(this.ref.current);
        this.setState({ wasVisible: true });
      }
    }
  }

  componentDidMount() {
    if (!this.alwaysRenderEvenIfOffscreen) {
      const isHidden = !isElementReallyVisible(this.ref.current);
      RenderIfVisible.elementMap.set(this.ref.current, {
        component: this,
        isHidden,
        isScrolledInView: isHidden,
      });
      RenderIfVisible.intersectionObserver.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    // These teardown calls are no-ops if unnecessary
    RenderIfVisible.elementMap.delete(this.ref.current);
    RenderIfVisible.intersectionObserver.unobserve(this.ref.current);
  }

  render() {
    const { wasVisible } = this.state;

    return <span ref={this.ref}>
      {(wasVisible || this.alwaysRenderEvenIfOffscreen) ? this.props.children : null }
    </span>;
  }
}
