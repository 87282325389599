const basicMiddleware = ({
  operations = {},
  delayedOperations = {},
}) => {
  return ({ dispatch, getState }) => next => action => {
    const operation = operations[action.type]
    const delayedOperation = delayedOperations[action.type]

    if (operation) {
      operation(action.payload)(dispatch, getState)
    }

    const nextAction = next(action)

    if (delayedOperation) {
      delayedOperation(action.payload)(dispatch, getState)
    }

    return nextAction
  }
}

export default basicMiddleware
