let count = 0

/**
 * This now supports a subscription model in which you can pass a function that is called when the loading count
 * changes. Each function is passed the count, and if any returns true then no further processing is done.
 */
let subscriptions = []

const subscribe = (func) => {
  subscriptions.push(func)
}

const unsubscribe = (func) => {
  subscriptions = subscriptions.filter(f => (f !== func))
}

const start = () => {
  count++
  for (let i = 0; i < subscriptions.length; i++) {
    if (subscriptions[i](count, true)) {
      return
    }
  }

  $('#globalAjax').show()
}

const stop = () => {
  count--
  for (let i = 0; i < subscriptions.length; i++) {
    if (subscriptions[i](count, false)) {
      return
    }
  }

  if (count < 1) {
    count = 0
    $('#globalAjax').hide()

    if (window.CDD && window.CDD.setupCalendarFields) {
      $(window.CDD.setupCalendarFields)
    }
  }
}

// XXX: only used for testing
const reset = () => {
  count = 0
  $('#globalAjax').hide()
}

const getCount = () => {
  return count
}

const activeAjax = {
  start,
  stop,
  reset,
  getCount,
  subscribe,
  unsubscribe
}

export default activeAjax
