import { OrderedMap, Record } from 'immutable'
import { SUGGEST_MOLECULES } from '@/shared/actions/moleculeSuggestions'

const DEFAULTS = {
  id: null,
  label: null,
  mrv: null,
  type: null,
}
class MoleculeSuggestion extends Record(DEFAULTS) {
  get highlighted() {
    return this
  }

  get display() {
    return [this.label]
  }
}

// State
const MoleculeSuggestions = OrderedMap

// Reducer
const suggestMolecules = (state, action) => {
  const { name, mrv } = action.payload
  return state.set(name, new MoleculeSuggestion({
    id: name,
    label: name,
    type: 'external',
    mrv,
  }))
}

const actionMap = {
  [SUGGEST_MOLECULES]: suggestMolecules,
}

const moleculeSuggestions = (state = new MoleculeSuggestions(), action) => {
  const fn = actionMap[action.type]
  return fn ? fn(state, action) : state
}

export { MoleculeSuggestion, MoleculeSuggestions, moleculeSuggestions }
