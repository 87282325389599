import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import { getReduxStore } from './reduxStore';
import { AnyObject } from '@/types';

type ComponentWithSubmitAndCancelProps = AnyObject & {
  onCancel?: () => void;
  onSubmit?: (value?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  onConfirm?: (value?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

type ComponentWithSubmitAndCancel = React.ComponentType<ComponentWithSubmitAndCancelProps>;
type FunctionComponentWithSubmitAndCancel = (props: ComponentWithSubmitAndCancelProps) => JSX.Element;

export function openModalComponent<ComponentType extends ComponentWithSubmitAndCancelProps | FunctionComponentWithSubmitAndCancel, // eslint-disable-line @typescript-eslint/no-unused-vars
  ResolveType = boolean>(
  Component: ComponentWithSubmitAndCancel,
  props: ComponentWithSubmitAndCancelProps,
  connectComponent = false) {
  const wrapper = document.body.appendChild(document.createElement('div'));
  const root = createRoot(wrapper);

  const promise = new Promise<ResolveType>((resolve, reject) => {
    let component = (
      <Component
        onSubmit={resolve}
        onConfirm={resolve}
        onCancel={reject}
        {...props}
      />
    );
    if (connectComponent) {
      component = <Provider store={getReduxStore()}>{ component }</Provider>;
    }
    root.render(component);
  });

  const dispose = () => {
    root.unmount();
    document.body.removeChild(wrapper);
  };

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    },
  );
}
