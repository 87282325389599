import sanitizeUrls from '../hocs/sanitizeUrls.jsx'

export const A = sanitizeUrls('a', 'href')
export const Applet = sanitizeUrls('applet', 'archive', 'codebase')
export const Area = sanitizeUrls('area', 'href')
export const Audio = sanitizeUrls('audio', 'src')
export const Base = sanitizeUrls('base', 'href')
export const Blockquote = sanitizeUrls('blockquote', 'cite')
export const Body = sanitizeUrls('body', 'background')
export const Button = sanitizeUrls('button', 'formaction')
export const Command = sanitizeUrls('command', 'icon')
export const Del = sanitizeUrls('del', 'cite')
export const Embed = sanitizeUrls('embed', 'src')
export const Form = sanitizeUrls('form', 'action')
export const Frame = sanitizeUrls('frame', 'longdesc', 'src')
export const Head = sanitizeUrls('head', 'profile')
export const Html = sanitizeUrls('html', 'manifest')
export const Iframe = sanitizeUrls('iframe', 'longdesc', 'src')
export const Img = sanitizeUrls('img', 'longdesc', 'src', 'srcset', 'usemap')
export const Input = sanitizeUrls('input', 'formaction', 'src', 'usemap')
export const Ins = sanitizeUrls('ins', 'cite')
export const Link = sanitizeUrls('link', 'href')
export const Meta = sanitizeUrls('meta', 'content')
export const Q = sanitizeUrls('q', 'cite')
export const Script = sanitizeUrls('script', 'src')
export const Source = sanitizeUrls('source', 'src', 'srcset')
export const Track = sanitizeUrls('track', 'src')
export const Video = sanitizeUrls('video', 'poster', 'src')

/*
 * TODO: Seems to cause problems with webpack:
 */
// export const Object = sanitizeUrls('object', 'archive', 'classid', 'codebase', 'data', 'usemap')
