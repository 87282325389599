import React from 'react'
import keycode from 'keycode'
import { OrderedMap } from 'immutable'
import { CircularProgress } from '@mui/material'

import { A } from '@/shared/components/sanitizedTags.js'

class KeyHandlerStack {
  constructor() {
    let handlers = OrderedMap()

    const callLastHandler = event => {
      const last = handlers.last()
      last(event)
    }

    this.on = (adder, handler) => {
      const wasEmpty = handlers.isEmpty()

      handlers = handlers.set(adder, handler)

      if (wasEmpty) {
        $(document).on('keydown', callLastHandler)
      }
    }

    this.off = adder => {
      handlers = handlers.delete(adder)

      if (handlers.isEmpty()) {
        $(document).off('keydown', callLastHandler)
      }
    }
  }
}
const keyHandlerStack = new KeyHandlerStack()

export default class SubmitOrCancel extends React.PureComponent {
  componentDidMount() {
    keyHandlerStack.on(this, this.handleKeys)
  }

  componentWillUnmount() {
    keyHandlerStack.off(this)
  }

  handleKeys = event => {
    const { onSubmit, onCancel, disabled } = this.props
    switch (keycode(event)) {
      case 'enter': {
        if (!disabled) {
          event.stopImmediatePropagation()
          onSubmit(event)
        }
        break
      }
      case 'esc': {
        if (onCancel) {
          event.stopImmediatePropagation()
          onCancel(event)
        }
        break
      }
    }
  }

  handleSubmit = () => {
    const { onSubmit, disabled } = this.props
    if (!disabled) {
      onSubmit()
    }
  }

  orCancel() {
    const { onCancel, noCancelOption, idCancel } = this.props

    if (!noCancelOption) {
      return (
        <span className="orcancel">
          <span>or </span>
          <A
            id={idCancel}
            className="cancel"
            onClick={onCancel}
            href="#"
          >
            cancel
          </A>
        </span>
      )
    }
  }

  renderSpinner = () => {
    const { loading, small } = this.props

    if (!loading) {
      return null
    } else {
      const size = small ? 18 : 24

      let className = 'circular_progress buttony__spinner'
      if (small) { className += ' buttony__spinner--small' }

      return <CircularProgress size={size} className={className} />
    }
  }

  render() {
    const { loading, small, onSubmit, green, submitLabel, linkUrl, disableMessage, idSubmit } = this.props
    const disabled = this.props.disabled || loading

    let className = 'buttony'
    if (small) { className += ' buttony-small' }
    if (green) { className += ' buttony-green' }
    if (disabled) { className += ' disabled' }

    const title = (disabled) ? disableMessage : ''

    return (
      <div className="buttons-react">
        <div className="buttons-right">
          <span className="buttony__container">
            <A
              id={idSubmit}
              className={className}
              title={title}
              onClick={this.handleSubmit}
              href={linkUrl || '#'}
              data-method={linkUrl ? 'post' : null}
            >
              {submitLabel || this.props.children}
            </A>
            {this.renderSpinner()}
          </span>
          {this.orCancel()}
        </div>
      </div>
    )
  }
}
