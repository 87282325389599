const SUGGEST_MOLECULES = 'eln/entry/molecules/suggest'
const suggestMolecules = ({ name, mrv }) => ({
  type: SUGGEST_MOLECULES,
  payload: { name, mrv },
})

const GET_MOLECULE_SUGGESTIONS = 'eln/entry/molecules/sugges/get'
const getMoleculeSuggestions = (name) => ({
  type: GET_MOLECULE_SUGGESTIONS,
  payload: { name },
})

export {
  GET_MOLECULE_SUGGESTIONS,
  SUGGEST_MOLECULES,
  getMoleculeSuggestions,
  suggestMolecules,
}
