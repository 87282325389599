import * as React from 'react';
import { TextField } from '@mui/material';

type Props = {
  value: string;
  placeholder?: string;
  onChangeValue: (value: string) => void;
};

export class SimpleFilter extends React.Component<Props> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeValue(e.target.value);
  };

  render() {
    const { value, placeholder } = this.props;
    return (
      <TextField
        className='simple-filter'
        value={value ?? ''}
        onChange={this.handleChange}
        placeholder={placeholder ?? '🔍 Filter by name'}
      />

    );
  }
}
