import { createStore, applyMiddleware } from 'redux'
import { combineReducers } from 'redux-immutable'
import { Record } from 'immutable'

import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'
import compose from '@/shared/utils/compose.js'

import { linkSuggestions, LinkSuggestions } from '@/shared/models/LinkSuggestion.js'
import { moleculeSuggestions, MoleculeSuggestions } from '@/shared/models/MoleculeSuggestions.js'
import linkSuggestionsMiddleware from '@/shared/middleware/linkSuggestions.js'
import moleculeSuggestionsMiddleware from '@/shared/middleware/moleculeSuggestions'

// Configure store for use outside of ELN

const CONSTANTS = {
  linkSuggestionsPath: '',
}

class Constants extends Record(CONSTANTS) { }

const constants = (state = {}, action) => {
  return state
}

const GENERIC_STATE = {
  constants: new Constants(),
  linkSuggestions: new LinkSuggestions(),
  moleculeSuggestions: new MoleculeSuggestions(),
}

class GenericState extends Record(GENERIC_STATE) {
  static initialState(data = {}) {
    return new GenericState(data)
  }
}

const configureGenericVaultStore = (data = {}) => {
  data = {
    ...data,
    constants: {
      linkSuggestionsPath: `/${CDD.ActiveDataContext.toContextParam}/link_suggestions`,
      ...data.constants,
    },
  }

  return createStore(
    compose(combineReducers({
      linkSuggestions,
      constants,
      moleculeSuggestions,
    })),
    GenericState.initialState(data),
    composeWithDevTools(
      applyMiddleware(
        linkSuggestionsMiddleware,
        moleculeSuggestionsMiddleware,
      )
    )
  )
}

const getReduxStore = () => {
  // On ELN entries, CDD.reduxStore is already defined; we can use that.
  if (CDD.reduxStore) return CDD.reduxStore

  CDD.reduxStore = configureGenericVaultStore()
  return CDD.reduxStore
}

export {
  getReduxStore,
}
