import React from 'react'
import sanitizeUrl from '@/shared/utils/sanitizeUrl'

const URI_ATTRS = [
  'action',
  'archive',
  'background',
  'cite',
  'classid',
  'codebase',
  'content',
  'data',
  'formaction',
  'href',
  'icon',
  'longdesc',
  'manifest',
  'poster',
  'src',
  'srcset',
  'usemap',
]

export default function sanitizeUrls(WrappedComponent, ...attrs) {
  attrs = attrs || URI_ATTRS

  return React.forwardRef((props, ref) => { // eslint-disable-line react/display-name
    props = Object.assign({}, props)

    attrs.forEach(attr => {
      if (props[attr] && props[attr] !== '#') {
        props[attr] = sanitizeUrl(props[attr])
      }
    })

    return <WrappedComponent ref={ref} {...props} />
  })
}
