let composeWithDevTools

if (process.env.NODE_ENV == 'development') {
  const compose = require('redux-devtools-extension/developmentOnly').composeWithDevTools
  const Immutable = require('immutable')

  composeWithDevTools = compose({
    serialize: {
      immutable: Immutable,
    },
  })
} else {
  composeWithDevTools = require('redux').compose
}

export default composeWithDevTools
